import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Colors } from '../../src/components/Core/Colors/Colors';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "colors"
    }}>{`Colors`}</h1>
    <h2 {...{
      "id": "primary"
    }}>{`Primary`}</h2>
    <Colors name="primary" mdxType="Colors" />
    <h2 {...{
      "id": "warning"
    }}>{`Warning`}</h2>
    <Colors name="warning" mdxType="Colors" />
    <h2 {...{
      "id": "error"
    }}>{`Error`}</h2>
    <Colors name="error" mdxType="Colors" />
    <h2 {...{
      "id": "info"
    }}>{`Info`}</h2>
    <Colors name="info" mdxType="Colors" />
    <h2 {...{
      "id": "text"
    }}>{`Text`}</h2>
    <Colors name="text" mdxType="Colors" />
    <h2 {...{
      "id": "background"
    }}>{`Background`}</h2>
    <Colors name="background" mdxType="Colors" />
    <h2 {...{
      "id": "actions"
    }}>{`Actions`}</h2>
    <Colors name="actions" mdxType="Colors" />
    <h2 {...{
      "id": "grey"
    }}>{`Grey`}</h2>
    <Colors name="grey" mdxType="Colors" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      